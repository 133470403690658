@import './variables.scss';
body{
    margin: 0;
}
.terms{
    &-container{
        & h1{
            font-family: $miniSerifBold;
        }
        @media(max-width: $xlarge){
            padding-left:20px !important;
            padding-right:20px !important;
        }
        @media(max-width: $minimobile){
            padding-bottom: 0 !important;
            width: 100%;
            max-width: 100%;
            padding-top: 35px !important;
        }
    }
    &-wrapper{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        background: url('./assets/images/login-bg-mini.jpg') no-repeat scroll 100% 0;
        background-size: cover;
        padding: 50px 0;
        font-family: $miniSerifRegular;
        @media (max-width: $minimobile){
            padding: 0;
            background: none;
        }
    }
    &-accept{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        #termsContent {
            & h1 {
              display: none;
            }
        }
        & .page-terms-and-conditions .terms-and-conditions-container{
            padding: 30px 30px 0px;
            @media (max-width: $xlarge){
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        & .terms-container{
            & .terms-action-buttons.action-buttons {
                margin: 20px 0 0 10px !important;
                & .terms-buttons{
                    @media(max-width: $minimobile){
                        width: 100% !important;
                    }
                    & .fs-button-btn{
                        @media(max-width: $minimobile){
                            margin: 20px 0 0 0 !important;
                        }
                    }
                }
                & .fs-button-btn-secondary{
                    & .fs-button-btn{
                        margin: 20px 0 0 10px !important
                    }
                }
                @media(max-width: $minimobile){
                    flex-direction: column;
                }
                
            }
            & .terms-checkbox{
                & .fs-anchor{
                    font-size: 13px;
                }
                & .fs-text.error{
                    font-size: 10px !important;
                    font-family: $helvetica;
                    margin: 5px 0 !important;
                }
            }
        }        
    }    
    &-content{
        font-family: $miniSerifRegular;
    }
}
app-terms-accept{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.mat-mdc-dialog-container{
    max-width: 80vw!important;
}
#sessionTimeOutDialog {
    padding: 40px 30px 20px !important;
    @media(max-width: $mobile) {
      max-width: 300px !important;
    }
    &.dialog-container{
      .fs-text.title{
        margin-top: 0;
        padding-top: 10px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 25px;
        line-height: 30px;
        color: var(--dark);
        font-family: $miniSerifBold;
        margin-bottom: 20px;
      }
      .dialog-bodytext{
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 20px;
        color: var(--dark);
        font-family: $miniSerifRegular;
        @media(max-width: $xmedium){
            font-size: 12px;
            line-height: 18px;
        }
      }
    }
    .dialog-buttonmultiple {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 20px;
        @media( max-width: $minismall){
            flex-direction: column-reverse;
        }
        .dialog-button{
            width: auto;
            display: inline-block;
            margin: 0;
            align-self: flex-end;
            @media( max-width: $minismall){
                width: 100%
            }
            .fs-button-btn{
                border-radius: 0;
                font-size: 16px;
                line-height: 16px;
                font-weight: normal;
                text-transform: uppercase;
                padding: 15px 30px;
                border: 1px solid var(--mini-red) !important;
                opacity: 1;
                cursor: pointer;
                background: var(--mini-red);
                margin: 20px 0 0 10px !important;
                display: flex;
                width: auto;
                font-family: $miniSansSerifRegular;
                &:hover{
                    background: var(--white) !important;
                    border: 1px solid rgb(var(--shadow-color)) !important;
                    color: rgb(var(--shadow-color)) !important;
                }
                &-secondary{
                    background: var(--white) !important;
                    border: 1px solid rgb(var(--shadow-color)) !important;
                    color: var(--dark) !important;
                    padding: 15px 30px !important;
                    &:hover{
                        background-color: rgb(var(--shadow-color));
                        color: var(--white);
                        border: 1px solid rgb(var(--shadow-color)) !important;
                    }
                }
                @media( max-width: $minismall){
                    width: 100%;
                    justify-content: center;
                    margin: 10px 0 0 0 !important;
                }
            }
        }     
    }
}